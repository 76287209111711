@font-face {
    font-family: 'Noto Sans';
    src: url('/fonts/Noto_Sans/NotoSans-Regular.ttf');
	font-weight: normal;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('/fonts/Noto_Sans/NotoSans-Bold.ttf');
	font-weight: bold;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('/fonts/Noto_Sans/NotoSans-Medium.ttf');
	font-weight: lighter;
}

// MAIN TAGS
body {
	font-family: 'Noto Sans', 'Open Sans', sans-serif;
}

#app {
	zoom: 90%;
}

table {
	width: 100%;
}

// COLORS
.bg-grey {
	background-color: lightgray;
	color: #1266f1;
}

.bg-dark-grey {
	background-color: #949494;
	color: white !important;
}

$colorSecondary: #233283;
$colorSecondaryLight: #435ba3;
.color-secondary {
	color: $colorSecondary !important;
}
.bg-secondary {
	background-color: $colorSecondary !important;
}
.border-secondary {
	border-color: $colorSecondary !important;
}

.bg-tercery {
    background-color: #b99090 !important;
}

$defaultDomainColor: orange;
// SERVER SIDE classes
// can be overwritten from the server domain config
.bg-domain-primary {
	background-color: $defaultDomainColor;
}
.border-color-domain-primary {
	border-color: $defaultDomainColor;
}
.color-domain-primary {
	color: $defaultDomainColor;
}

.btn-primary {
	background-color: $defaultDomainColor;
}
.nav-item .router-link-active .btn-primary {
	background-color: $defaultDomainColor;
}

// MAIN CLASSES
.animation-rotation {
	animation: rotation 2s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.cursor-pointer {
	cursor: pointer;
}

.fa-big:before {
	font-size: 1.2rem;
}

.width-100 {
	width: 100%;
}

.text-align-right {
	text-align: right;
}
.text-align-left {
	text-align: left;
}

.text-transform-capitalize {
	text-transform: capitalize;
}

.box-shadow-none {
	box-shadow: none;
}

.toast-header {
	color: white;
	background-color: #1266f1;
}

.toast-body {
	background-color: #dadada;
}

.table {
	th {
		font-weight: inherit;
	}
}

#liveStatus {
	font-size: 1.3rem;

	&.connecting {
		color: orange;
		animation: opacity 2s infinite linear;
	}
	&.connected {
		color: inherit;
	}
	&.closed {
		color: orange;
	}
}

.animation-fade {
	animation: opacity 2s infinite linear;
}

@keyframes opacity {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.3;
	}
	100% {
		opacity: 1;
	}
}

.table-responsive table {
	margin-bottom: 0;
}

.navbar {
	background-color: transparent;
	box-shadow: none;
	color: white;
}

footer {
	background-color: #335058;
	color: white;
}

.navbar-brand {
	align-items: baseline;
}

.w-20 {
	width: 20px;
}

.table thead th {
	font-weight: bold;
}

body #tarteaucitronIcon #tarteaucitronManager img {
	width: 30px;
    height: 30px;
}
body #tarteaucitronIcon #tarteaucitronManager {
	padding: 0px 10px;
}

.white-space-nowrap {
	white-space: nowrap;
}

body .table>:not(caption)>*>* {
	padding: .3rem .5rem;
}

td, th {
    vertical-align: middle;
}

.table-striped tr {
	&.isCurrent {
		background-color: #f69f9f;
	}
	&.isPrevious {
		background-color: #bcbcbc;
	}
}

.text-green {
	color: green;
}
.text-red {
	color: red;
}
.text-grey {
	color: grey;
}

.inline-flex-center-end {
	display: inline-flex;
    align-items: center;
    justify-content: flex-end;
}

input[type="color"] {
	border: 0;
}

.form-label {
	color: inherit;
	margin-bottom: 0;
}

.btn-check:not(checked) + .btn {
	opacity: .5;
}
.btn-check:checked + .btn {
	opacity: 1;
}

#page-home {
	min-height: 100%;

	main {
		min-height: calc(100% - 59px);
		padding-bottom: 27px;
	}

	.card {
		color: black;

		.badge {
			font-size: 0.9rem;
		}
	}

	#cards-home {
		.card-title-container {
			padding: 4px 8px;
			border-bottom: 2px solid $defaultDomainColor;
		}

		.card-img-top {
			height: 68px;
			object-fit: cover;
		}

		.card-title {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 74px;
			margin-bottom: 0;
		}

		.card-text {
			padding: 5px;
		}
	}

	.competitions-filters {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: 10px 0;
		padding: 10px;

		ul {
			margin: 0;
			padding: 0;

			&.list-filters {
				li.active .badge {
					// background-color dynamically updated (main.js)
					color: white!important;
				}
			}

			&.list-years {
				li.active {
					top: 2px;
					position: relative;
					padding: 0 10px;
				}
			}

			li {
				margin: 0;
				margin-bottom: 10px;
				padding: 0;
				cursor: pointer;

				.badge {
					background-color: white;
				}
			}
		}
	}

}

html {
	background: url('/images/background_athle.jpg') no-repeat center center fixed;
	background-color: $colorSecondary;
	background-size: cover;

	body {
		background-color: transparent;
	}
}

.tab-panel:not(.active) {
	display: none;
}

#page-event {
	.nav-tabs {
		margin-top: 15px;
		justify-content: center;

		.nav-link {
			border: 1px solid #e2e2e2;
			font-size: 1.1rem;
			border-radius: 32px;
			padding: 11px 23px;
			color: white;
			margin: 7px 7px;

			&.active {
				color: white;
			}

			&:not(.active) {
				background-color: transparent !important;
			}
		}
	}

	.tab-panel .table-responsive {
		border-radius: 10px;
	}

	.table td {
		padding: .2rem .3rem;
		align-self: center;

		.d-flex > div {
			align-self: center;
		}
	}

	.table thead th {
		font-weight: lighter;
		font-size: .9rem;
	}

	.table tbody tr {
		border-bottom: 4px solid #23328340;

		&:last-child {
			border-bottom: 0px !important;
		}
	}

	.table-striped > tbody > tr.table-striped-item > * {
		background-color: white;
		color: $colorSecondary;
	}
	.table-striped > tbody > tr.table-striped-content > * {
		background-color: $colorSecondaryLight;
		color: white;
	}
	.table-not-striped tbody tr {
		background-color: white;
		color: $colorSecondary;
	}

	.table-bordered >:not(caption) > * > * {
		border-width: 5px 5px;
		border-color: $colorSecondary;
	}

	.avatar {
		display: inline-block;
		border-radius: 50%;
		border-style: solid;
		border-width: 2px;
		height: 2.2em;
		width: 2.2em;
	}
}

.ffa-event-list  {
	a {
		display: inline-block;
		width: 100%;

		color: white;
		word-break: break-word;
	}

	.ffa-event-list-item {
		border-radius: 5px;
		box-shadow: 0 2px 1px 0 rgb(0 0 0 / 8%), 0 3px 2px -2px rgb(0 0 0 / 5%), 0 1px 5px 0 rgb(0 0 0 / 12%);

		.ffa-event-list-item-date {
			height: 32px;
			line-height: 30px;

			border-radius: 5px 0 0 5px;
			padding: 0 10px;
		}
		.ffa-event-list-item-category {
			height: 32px;
			line-height: 30px;
			padding: 0 10px;
			background-color: black;
			color: white;
			word-break: break-all;
		}
		.ffa-event-list-item-status {
			margin-left: auto;

			.badge {
				height: 32px;
				line-height: 21px;
			}
		}
	}
}

#page-competition {
	.open-button {
		z-index: 99;
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		bottom: 90px;
		right: 22px;
		color: #fff;
		border-radius: 50%;
		width: 60px;
		height: 60px;
	}
	.miniplayer {
		z-index: 99;
		position: fixed;
		bottom: 90px;
		right: 10px;
		box-shadow: 0 0 2px 0 rgb(0 0 0 / 75%);
		color: #fff;

		.player-header {
			display: flex;
			padding: 0 5px;
			align-items: center;
			justify-content: space-between;
		}
		.player-title {
			font-style: italic;
			font-weight: 700;
			text-align: center;
			line-height: 40px;
		}
	}

	.tabs-competition {
		.nav-link:not(.router-link-exact-active) {
			background-color: black!important;
			color: white!important;
		}
	}
}

#page-event {
	.event-records {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 20px;
	}
}

.chip {
	cursor: pointer;
	margin: 5px 5px;

	.chip-header, .chip-content, .chip-end {
		padding: 3px 6px;
		height: 100%;
		text-align: center;
		border: 1px solid black;
	}

	.chip-header {
		border: 1px solid black;
		color: white;
		border-radius: 10px 0 0 10px;
	}

	.chip-content {
		border-left: 0;
	}

	.chip-end {
		border-radius: 0 10px 10px 0;
		border-left: 0;
	}
}

.form-outline .form-control.active~.form-label, .form-outline .form-control:focus~.form-label {
	display: none;
}

.carousel-inner {
	padding: 20px;
}

.form-search {
	.form-control {
		border: 1px solid white;
		background-color: white;
		color: black;
		width: 285px;
		display: inline-block;
		padding: 4.5px 12px 3.68px;
		border-radius: 5px 0 0 5px;
	}

	button[type="button"] {
		border-radius: 0 5px 5px 0;
	}
}

.card.inverted {
	background-color: black;
	color: white;
}

.jumpBarsResults {
    display: flex;
    flex-wrap: wrap;

	.jumpBarResult {
		position: relative;
		margin: .2rem;
		display: flex;
		flex-direction: column;

		.jumpBar {
			display: inline-flex;
			font-size: .75rem;
			height: 2em;
			justify-content: center;
			align-items: center;
			line-height: 1.5;
			background-color: $colorSecondary;
			color: #fff;
			padding-left: 1rem;
			padding-right: 1rem;
			border-radius: 4px 4px 0 0;
			border: 2px solid $colorSecondary;
			border-bottom: 0px;
		}

		.jumpResults {
			display: inline-flex;
			border: 1px solid $colorSecondary;
			border-radius: 0 0 4px 4px;

			.jumpResult {
				flex: 1;
				margin: 1px;
				height: 16px;
				display: flex;
				background: #f5f5f5;
				justify-content: center;
				align-items: center;
			}
		}

		.success {
			background: #effaf3;
			color: #48c774;
		}
		.fail {
			background: #fdeded;
			color: #e53935;
		}
		.skip {
			background: #fffbeb;
			color: black;
		}
	}
}

.text-wrap-ellipsis {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	vertical-align: text-bottom;
}

.loader {
	position: absolute;
	left: 0;
    right: 0;
    z-index: 10000;
	padding: 124px;
	color: white;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	main {
		max-width: 970px;
		margin-left: auto;
		margin-right: auto;
	}
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
	main {
		max-width: 70%;
		margin-left: auto;
		margin-right: auto;
	}
}

.item-max-width-responsive {
	max-width: 161px;

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 400px) {
		max-width: 200px;
	}

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 440px) {
		max-width: 243px;
	}

	@media (min-width: 510px) {
		max-width: 280px;
	}

	@media (min-width: 580px) {
		max-width: 349px;
	}

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 620px) {
		max-width: 468px;
	}

	// Medium devices (tablets, 768px and up)
	@media (min-width: 768px) {
		max-width: 600px;
	}
}

#modal-rider {

	.list-group {
		width: 223px;

		.list-group-item {
			padding: 0;
			margin: 0;

			a {
				display: inline-block;
				padding: 10px;
				width: 100%;

				i {
					width: 30px;
				}
			}
		}
	}
}

.btn-floating {
	display: inline-block;
	border-radius: 50%;
	border-width: 2px;
	position: fixed;
    bottom: 100px;
    right: 80px;
	text-align: center;
	height: 60px;
    width: 60px;
    line-height: 60px;
	font-size: 1.4rem;
	cursor: pointer;
	color: white;
}

.heights-list {
    position: fixed;
    right: 62px;
    bottom: 167px;
}
